<template>
<div>

    <nav class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
        <button @click="navbarMenu" class="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><i class="fas fa-bars"></i></button>
        <a class="navbar-brand pe-3 ps-4 ps-lg-2">
            <img @click="redirectHome" class="img-navbar" style="height:65px !important" src="assets/img/art.png">
        </a>
        <!-- Navbar Items-->
        <ul class="navbar-nav align-items-center ms-auto">
            <!-- Alerts Dropdown-->
            <!-- <li class="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications">
                <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownAlerts" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="far fa-bell"></i></a>
                <div class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownAlerts">
                    <h6 class="dropdown-header dropdown-notifications-header">
                        Centro de Notificaciones
                    </h6>
                    <div v-if="this.listaNotificaciones == null || this.listaNotificaciones.length == 0" style="text-align: center;padding: 10px;">No hay notificaciones.</div>

                    <Alerta v-for="(dato, index) in this.listaNotificaciones" :key="dato.id" :infoMensaje=dato :i=index />

                    <a class="dropdown-item dropdown-notifications-footer"  @click="redirectHomeMenu('notificaciones')">Ver todas las notificaciones</a>
                </div>
            </li> -->
            <!-- Messages Dropdown-->
            <!-- <li class="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications">
                <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownMessages" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="far fa-envelope"></i></a>
                <div class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownMessages">
                    <h6 class="dropdown-header dropdown-notifications-header">
                        <i class="me-2" data-feather="mail"></i>
                        Centro de Mensajes
                    </h6>
                    <div v-if="this.listaMensajes == null || this.listaMensajes.length == 0" style="text-align: center;padding: 10px;">No hay mensajes.</div>
                    <Mensajes v-for="(dato, index) in this.listaMensajes" :key="dato.id" :infoMensaje=dato :i=index />
                    <a v-if="this.listaMensajes != null && this.listaMensajes.length != 0" class="dropdown-item dropdown-notifications-footer" @click="redirectHomeMenu('mensajes')">Ver todos los mensajes</a>
                </div>
            </li> -->
            <li class="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-user-circle user-profile"></i></a>
                <div class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                    <h6 class="dropdown-header d-flex align-items-center">
                        <i class="fas fa-user-alt dropdown-user-img"></i>
                        <div class="dropdown-user-details">
                            <div class="dropdown-user-details-name">{{this.$store.state.user.user.apellido}} {{this.$store.state.user.user.nombre}}</div>
                            <div class="dropdown-user-details-email">{{this.user}}</div>
                        </div>
                    </h6>
                    <div class="dropdown-divider"></div>
                    <!-- <a class="dropdown-item cursor-pointer" @click="redirectHomeMenu('autogestion')">
                        <div class="dropdown-item-icon "><i data-feather="settings"></i></div>
                        Autogesti&oacute;n
                    </a> -->
                    <!-- <a class="dropdown-item cursor-pointer" @click="redirectCambiarEmpresa" v-if="this.listEmpresas != null ">
                        <div class="dropdown-item-icon cursor-pointer"><i data-feather="log-out"></i></div>
                        Cambiar Empresa
                    </a> -->
                    <a class="dropdown-item cursor-pointer" @click="redirectLanding">
                        <div class="dropdown-item-icon cursor-pointer"><i data-feather="log-out"></i></div>
                        Salir
                    </a>
                </div>
            </li>
        </ul>
    </nav>
</div>
</template>

<style>
.img-navbar {
    cursor: pointer;
    height: 65px !important;
    margin: 1px;
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-profile {
    font-size: 35px;
    color: rgb(0, 47, 135);
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import Mensajes from '../navbar/mensaje'
import Alerta from '../navbar/alert'
export default {
    components: { Mensajes, Alerta },
    mounted: function () {
        //ejectuar cuando inicia
    },
    methods: {
        redirectHome() {
            router.push("/home")
        },
        redirectLanding() {
            this.$store.commit("auth/SET_TOKEN", null);
            this.$store.commit("auth/SET_REFRESHTOKEN", null);

            router.push("/")
        },
        navbarMenu() {
            let responsive = document.body.classList.contains('sidenav-toggled')
            let body = document.body;
            if (!responsive) {
                body.classList.add("sidenav-toggled");
            } else {
                body.classList.remove("sidenav-toggled");
            }

        },
        redirectHomeMenu(data) {
            this.$store.commit("menu/SET_ACTIVO", "" + data);
            router.push("/" + data);
        },
        redirectCambiarEmpresa() {
            router.push("/loginEmpresas");
        }
    },
    computed: {
        //mapea store con la vista
        user: {
            get() {
                return this.$store.state.login.user;
            },
        },
        listaMensajes: {
            get() {},
            get() {
                return this.$store.state.mensajes.listaMensajes;
            }
        },
        listaNotificaciones: {
            get() {},
            get() {
                return this.$store.state.notificaciones.listaNotificaciones;
            }
        },
        listEmpresas: {
            get() {
                return this.$store.state.login.empresas_by_user;
            },
        },
    },
    props: {}
}
</script>>
