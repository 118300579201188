<template>
  <div :class="{ ocultar: isActive }">
    <a class="dropdown-item dropdown-notifications-item" href="#!">
      <div class="dropdown-notifications-item-icon bg-warning">
        <i class="fas fa-bullhorn"></i>
      </div>
      <div class="dropdown-notifications-item-content">
        <div class="dropdown-notifications-item-content-details">
          {{ formateDate(infoMensaje.fecha_alta) }}
        </div>
        <div class="dropdown-notifications-item-content-text">
          {{ vistaPrevia(infoMensaje.descripcion) }}
        </div>
      </div>
    </a>
  </div>
</template>

<style>

.ocultar {
  display: none;
}

</style>

<script>
import router from "@/router/index.js"; // Vue router instance
import UtilJS from "../../../assets/js/util";

export default {
  data() {
    return {
      isActive: false,
    };
  },
  components: {},
  mixins: [UtilJS],
  mounted: 
function () {
        this.mostrarNotificacion();
    },
  methods: {
    vistaPrevia(text) {
      return text.substring(0, 20) + "...";
    },    
    mostrarNotificacion() {
      if(this.i > 2) {
          this.isActive = true;
      }
    },
  },
  computed: {},
  props: ["infoMensaje", "i"],
};
</script>>
