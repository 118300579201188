<template>
<div>
    <!-- navbar -->
    <Navbar />
    <Spinner />
    <div id="layoutSidenav">
        <Menu />
        <div id="layoutSidenav_content">
            <main>
                <!-- header -->
                <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div class="container-xl px-4">
                        <div class="page-header-content pt-4">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto mt-4">
                                    <h1 class="page-header-title">
                                        <div class="page-header-icon"><i class="fas fa-question"></i></div>
                                        Preguntas Frecuentes
                                    </h1>
                                    <div class="page-header-subtitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <!-- fin header -->
                <!-- body -->
                <div class="container-xl px-4 mt-n10">
                    <!-- Wizard card navigation-->
                    <div class="card mb-5">
                        <div class="card-header border-bottom">
                            <!-- Wizard navigation-->
                            <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                <!-- Wizard navigation item 2-->
                                <CardButton title="Preguntas Frecuentes" active="active" subTitle="" id="ingresados" :numberCard="cantidadPreguntas" />
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="tab-content" id="cardTabContent">

                                <div v-if="this.listaPreguntas != null">
                                    <div style="text-align: center;color:rgb(244, 161, 0)" v-if="this.listaPreguntas.length == 0">No se encontraron preguntas de ayuda.</div>
                                    <AyudaComp v-for="(dato) in this.listaPreguntas" :key="dato.id" active="active" :infoAyuda=dato />
                                </div>
                                <span style="color:#f4a100" v-if="this.errorListaAyuda != ''">{{errorListaAyuda}}</span>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>
.title-pregunta {
    font-weight: 700;
    white-space: normal;
    text-align: left;
    font-size: 18px;
}

.color-circle {
    color: #1f3c7b
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import Spinner from '../../../components/spinner'
import Vue from 'vue'
import Navbar from '../../../components/home/navbar/navbar'
import Menu from '../../../components/home/menu'
import CardButton from '../../../components/home/step/util/cardButton'

import AyudaComp from '../../../components/home/step/ayuda/ayudaComp' 

export default {
    name: "Home",
    components: {
        Spinner,
        Navbar,
        Menu,
        CardButton,
        AyudaComp
    },
    mounted: function () {
        this.findPreguntasByTipoProveedor();
    },
    methods: {
        findPreguntasByTipoProveedor() {
            this.$store.dispatch("ayuda/findPreguntasByTipoProveedor")
        }
    },
    computed: {
        listaPreguntas: {
            get() {
                return this.$store.state.ayuda.listaPreguntas;
            }
        },
        cantidadPreguntas: {
            get() {
                return this.$store.state.ayuda.cantidadPreguntas;
            }
        },         
        errorListaAyuda: {
            get() {
                return this.$store.state.ayuda.ayudaError;
            }
        }, 
    },
};
</script>
