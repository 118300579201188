<template>
<div>

    <div id="layoutSidenav_nav">
        <nav class="sidenav shadow-right sidenav-light">
            <div class="sidenav-menu">
                <div class="nav accordion" id="accordionSidenav">
                    <div class="sidenav-menu-heading">Inicio</div>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_home" @click="redirectHomeMenu('home')" data-bs-toggle="collapse" data-bs-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
                        <div class="nav-link-icon"><i class="fas fa-home"></i></div>
                        Inicio
                    </a>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_autogestion" @click="redirectHomeMenu('autogestion')" data-bs-toggle="collapse" data-bs-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
                        <div class="nav-link-icon"><i class="fas fa-user-edit"></i></div>
                        Mi Perfil
                    </a>
                    <div class="sidenav-menu-heading">Gesti&oacute;n</div>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_consultagenerico" @click="redirectHomeMenu('consultaGenerico')" data-bs-toggle="collapse" data-bs-target="#collapseFlowsIngreso" aria-expanded="false" aria-controls="collapseFlowsIngreso">
                        <div class="nav-link-icon"><i class="fas fa-search"></i></div>
                        Consultas
                    </a>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_ingresogenerico" @click="redirectHomeMenu('ingresoGenerico')" data-bs-toggle="collapse" data-bs-target="#collapseFlowsIngreso" aria-expanded="false" aria-controls="collapseFlowsIngreso">
                        <div class="nav-link-icon"><i class="fas fa-folder-plus"></i></div>
                        Gestiones Disponibles
                    </a>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_generico" @click="redirectHomeMenu('generico')" data-bs-toggle="collapse" data-bs-target="#collapseFlows" aria-expanded="false" aria-controls="collapseFlows">
                        <div class="nav-link-icon"><i class="fas fa-folder"></i></div>
                        Mis Gestiones
                    </a>
                    <div class="sidenav-menu-heading">COMUNICACIONES</div>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_mensajes" @click="redirectHomeMenu('mensajes')" data-bs-toggle="collapse" data-bs-target="#collapseNotifiaciones" aria-expanded="false" aria-controls="collapsePages">
                        <div class="nav-link-icon"><i class="fas fa-envelope"></i></div>
                        Notificaciones <h4 class="m-0 ms-3 mb-1 animated--fade-in"><span v-if="notificacionesNoLeidas > 0" class="badge bg-ps-blue text-white" style="padding: 0.2rem 0.4rem;">{{notificacionesNoLeidas}}</span></h4>
                    </a>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_notificaciones" @click="redirectHomeMenu('notificaciones')" data-bs-toggle="collapse" data-bs-target="#collapseAppsz" aria-expanded="false" aria-controls="collapseApps">
                        <div class="nav-link-icon"> <i class="fas fa-bell"></i></div>
                        Anuncios
                    </a>
                    <div class="sidenav-menu-heading">DOCUMENTOS</div>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_descargas" @click="redirectHomeMenu('descargas')" data-bs-toggle="collapse" data-bs-target="#collapseDescargas" aria-expanded="false" aria-controls="collapsePages">
                        <div class="nav-link-icon"><i class="fas fa-download"></i></div>
                        Descargas
                    </a>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_help" @click="redirectHomeMenu('help')" data-bs-toggle="collapse" data-bs-target="#collapseDescargas" aria-expanded="false" aria-controls="collapsePages">
                        <div class="nav-link-icon"><i class="fas fa-question"></i></div>
                        Preguntas Frecuentes
                    </a>
                    <a class="nav-link collapsed title cursor-pointer" id="menu_descargas" @click="singOut()" data-bs-toggle="collapse" data-bs-target="#collapseDescargas" aria-expanded="false" aria-controls="collapsePages">
                        <div class="nav-link-icon"><i class="fas fa-sign-out-alt"></i></div>
                        Salir
                    </a>
                </div>
            </div>
            <!-- Sidenav Footer-->
            <div class="sidenav-footer" style="height:7rem !important">
                <div class="row mt-2 mb-2">
                    <!-- <div class="col-md-12 mt-2 mb-2">
                        <div class="sidenav-footer-content">
                            <div class="sidenav-footer-subtitle">Usuaria/o:</div>
                            <div class="sidenav-footer-title">{{ this.user }}</div>
                        </div>
                    </div> -->
                    <div class="sidenav-footer-content">
                        <div class="sidenav-footer-subtitle">Empresa:</div>
                        <div class="sidenav-footer-title">{{this.empresa.razon_social }}</div>
                    </div>
                </div>
            </div>
        </nav>
    </div>

</div>
</template>

<style>

:root {
    --bs-ps-blue-rgb: 0, 47, 135;
}

.bg-ps-blue {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-ps-blue-rgb), var(--bs-bg-opacity)) !important;
}

.title {
    color: black !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

#accordionSidenav {
    overflow-y: hidden !important;
}

.span-menu-pendiente {
    font-size: 12px;
    color: #d1d102
}

.span-menu-aceptado {
    font-size: 12px;
    color: green
}

.itemOn {
    color: #002f87 !important;
    font-weight: 500;
    letter-spacing: 1px;
}

.notificacion-mensaje {
    margin-left: 10px;
    background: #e47979;
    padding: 2px 7px 2px 8px;
    border-radius: 12px;
    color: white !important;
    font-size: 13px;
    text-decoration: none !important;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {

    components: {},
    mounted: function () {
        this.resaltarSeleccionado(this.$route.name);
    },
    methods: {
        redirectHomeMenu(data) {
            // this.$store.dispatch("notificaciones/getNotificacionMenu");
            // this.$store.dispatch("mensajes/getNotificacionMensaje");
            this.$store.commit("menu/SET_ACTIVO", "" + data);
            router.push("/" + data);
        },
        resaltarSeleccionado(path) {
            let activo;

            if (path == "altaNuevoTramiteGeneric") {
                activo = "menu_" + this.activoMenu.toLowerCase();
                this.$store.commit("menu/SET_ACTIVO", path);
            } else {
                activo = "menu_" + path.toLowerCase();
                this.$store.commit("menu/SET_ACTIVO", path);
            }
            document.getElementById(activo).classList.add("itemOn");
            document.getElementById(activo).children[0].classList.add("itemOn")
            document.getElementById(activo).classList.remove("title");
        },
        singOut() {
            this.$store.commit("auth/SET_TOKEN", null);
            this.$store.commit("auth/SET_REFRESHTOKEN", null);
            router.push("/")
        }
    },
    computed: {
        user: {
            get() {
                return this.$store.state.login.user;
            },
        },
        empresa: {
            get() {
                return this.$store.state.user.user.empresa;
            },
        },
        notificacionesMensaje: {
            get() {
                return this.$store.state.mensajes.cantidadMensajeNotificacion;
            },
        },
        notificaciones: {
            get() {
                return this.$store.state.notificaciones.cantidadNotificacion;
            },
        },
        notificacionesNoLeidas: {
            get() {
                if (this.$store.state.mensajes.mensajesNoLeidos > 99) {
                    return '+99';
                }
                return this.$store.state.mensajes.mensajesNoLeidos;
            }
        },
        activoMenu: {
            get() {
                return this.$store.state.menu.activo;
            },
        },
    },
    props: {
        pantalla: Number
    }
}
</script>>
