<template>
    <div class="row row justify-content-center">
        <div class="col-md-8"><i class="fas fa-circle color-circle"></i><span class="title-pregunta ms-2">{{infoAyuda.titulo}}</span></div>
        <div class="col-md-8 ms-2 mt-4">
            <p>{{infoAyuda.descripcion}}</p>
        </div>
        <div class="col-md-8 ms-2">
            <hr>
        </div>
    </div>
</template>

<style>
</style>

<script>
import UtilJS from '../../../../assets/js/util'

export default {
    
    name: "Ayuda", 
    mixins: [UtilJS],   
    props: ['infoAyuda'],
    mounted: function () {
    },
    methods: {
    },

}
</script>
