<template>
<div :class="{ ocultar: isActive }">
    <a class="dropdown-item dropdown-notifications-item">
        <div class="dropdown-notifications-item-icon bg-warning" style="background-color:rgb(82, 182, 154) !important"><svg class="svg-inline--fa fa-bullhorn fa-w-18" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullhorn" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg><!-- <i class="fas fa-bullhorn"></i> Font Awesome fontawesome.com --></div>
        <div class="dropdown-notifications-item-content">
            <div class="dropdown-notifications-item-content-text">{{infoMensaje.titulo}}</div>
            <div class="dropdown-notifications-item-content-details">{{vistaPrevia(infoMensaje.descripcion)}}</div>
        </div>
    </a>
</div>
</template>

<style>

.ocultar {
  display: none;
}

</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    data() {
        return {
        isActive: false,
        };
    },
    components: {},
    mounted: 
    function () {
        this.mostrarNotificacion();
    },
    methods: {
        vistaPrevia (text) {
            return text.substring(0,20) + "..."
        },
        mostrarNotificacion() {
            if(this.i > 2) {
                this.isActive = true;
            }
        },
    },
    computed: {},
    props: ['infoMensaje', 'i']
}
</script>>
